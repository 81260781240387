<template>
  <v-row>
    <v-dialog v-model="model" max-width="400" persistent>
      <v-card>
        <v-card-title v-if="title" class="headline">
          <span v-html="title" />
        </v-card-title>
        <v-divider class="mb-2" />
        <v-card-text><span v-html="content"></span></v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="loadingBtn" outlined color="primary" @click="close">
            {{ $t("app.back") }}
          </v-btn>
          <v-btn
            :loading="loadingBtn"
            color="gradient-primary"
            dark
            depressed
            @click="save"
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    model: {
      type: Boolean,
      required: true
    },
    title: String,
    content: {
      type: String,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    save: {
      type: Function,
      required: true
    },
    loadingBtn: {
      type: Boolean,
      required: true
    }
  }
};
</script>
